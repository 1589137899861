
import { Injectable } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';
import { ElderData } from './select-elder/elder_data';
import { SafeUrl } from '@angular/platform-browser';
import { Menu } from './menu';

declare var PUBNUB: any;
declare var Parse: any;
declare var $: any;

@Injectable()
export class GlobalService {

  //public username = new Subject<string>();
  // for subscription
  username = new Subject<string>();
  menuItems = new Subject<Menu[]>();
  settingMenuItems = new Subject<Menu[]>();


  _MenuItems: Menu[] = [];
  _SettingMenuItems: Menu[] = [];
  focusParseUserObject: ElderData;
  focusParseUserObjectOrigin: any;

  CareRecordInfoObject;
  adminACLString = "mabowadmin";
  readonlyAdminACLString = "readonlymabowadmin";
  userACLString;
  isIamAdminUser = false;
  communicationTool: string = "";
  eldermanagementdetailgroup: number = -1;
  profile_image: SafeUrl;
  broadcastroom = "";
  broadcastroom_role = 0;



  constructor() {
    console.log("in GlobalService constructor" + window.location.origin);
    Parse.initialize("EamJQt5W2aw1sg5ZBfdpQcXTbJWjdJv8iIMDgxms");
    if (window.location.hostname == "localhost") {
      //Parse.serverURL = 'http://localhost:1337/parse'
     Parse.serverURL = 'https://test.mabow.com.tw/parse'
     //Parse.serverURL = 'https://app.mabow.com.tw/parse'

    } else {
      if (window.location.origin == "https://testdashboard.mabow.com.tw")
        Parse.serverURL = 'https://test.mabow.com.tw/parse'
      else if (window.location.origin == "https://dashboard.mabow.com.tw")
        Parse.serverURL = 'https://app.mabow.com.tw/parse'
      else
        Parse.serverURL = 'https://app.mabow.com.tw/parse'
      //Parse.serverURL = window.location.origin + '/parse'
    }
    console.log("in GlobalService " + Parse.serverURL);

    this.checkIsAdminUser();
    this.queryAvalibleAllMenu();
  }


  checkIsAdminUser() {

    var queryRole = new Parse.Query(Parse.Role);
    queryRole.equalTo('name', 'mabowadmin');
    queryRole.first({
      success: (result) => { // Role Object
        var role = result;
        var adminRelation = new Parse.Relation(role, 'users');
        var queryAdmins = adminRelation.query();
        queryAdmins.equalTo('objectId', Parse.User.current().id);
        queryAdmins.first({
          success: (result) => {    // User Object
            var user = result;
            user ? this.isIamAdminUser = true : console.log('Shiet, user not Admin');
          }
        });
      },
      error: function (error) {
        console.log("Bruh, can't find the Admin role");
      }
    }).then(() => {
      console.log('After test: Auth = ' + this.isIamAdminUser);
    });
  }
  setMenu() {
    this.menuItems.next(this._MenuItems);
    this.settingMenuItems.next(this._SettingMenuItems);

  }

  getMenu(): Observable<Menu[]> {
    return this.menuItems.asObservable();
  }

  getSettingMenu(): Observable<Menu[]> {
    return this.settingMenuItems.asObservable();
  }

  /**
   * set the menu for client
   */
  queryAvalibleAllMenu() {
    console.log("queryAvalibleAllMenu() ");

    this._SettingMenuItems = [];
    this._MenuItems = [];
    console.log("queryAvalibleAllMenu()  2");

    var menuitem = Parse.Object.extend("MenuItem");
    var query = new Parse.Query(menuitem);
    query.equalTo('type', "dashboard");
    query.ascending("order");
    query.find()
      .then((results) => {
        results.forEach(result => {
          //console.log(result.get("name"))
          if (result.get("location") == "main") {
            var menu: Menu = new Menu();
            menu.title = result.get("name")
            menu.url = result.get("route")
            this._MenuItems.push(menu);
          } else if (result.get("location") == "special") {
            // enable call-in function
            //this.backGroundCommunicationService.enableCallinFunction(true);

          } else {
            var _settingMenuItems: Menu = new Menu();
            _settingMenuItems.title = result.get("name")
            _settingMenuItems.url = result.get("route")
            _settingMenuItems.icon = result.get("icon")
            this._SettingMenuItems.push(_settingMenuItems);
          }
        });
        console.log("queryAvalibleAllMenu/ setMenu")
        this.setMenu();
      }).catch((e) => {
        console.error("in queryAvalibleAllMenu/ error " + e);

      })
  }


  /**
   * Meta for CustomAlert
   */
  customAlertmeta = {
    head: "",
    content: "",
    isConfirm: false,

    head2: "",
    content2: "",
    isConfirm2: false,
    type2: 1,
    TYPESUCCESS: 3,
    TYPEWARN: 2,
    TYPEINFO: 1,
    TYPEERROR: -1,

  }

  /**
   * showcustomAlert
   * start simple alert
   * @param msg 
   * @param callback 
   */
  //TODO 加上queue 機制
  showcustomAlert(head: string, msg: string, isConfirm: boolean, sb, fb) {
    this.customAlertmeta.content = msg;
    this.customAlertmeta.head = head;
    this.customAlertmeta.isConfirm = isConfirm;
    $('.ui.basic.modal')
      .modal({
        closable: false,
        onDeny: fb,
        onApprove: sb
      })
      .modal('show')
  }

  /**
   * showcustomAlert
   * start simple alert
   * @param msg 
   * @param callback 
   */
  showcustomAlert2(head: string, msg: string, isConfirm: boolean, sb, fb, type: number) {
    this.customAlertmeta.content2 = msg;
    this.customAlertmeta.head2 = head;
    this.customAlertmeta.isConfirm2 = isConfirm;
    this.customAlertmeta.type2 = type;

    $('.ui.normal.modal')
      .modal({
        inverted: true,
        closable: false,
        onDeny: fb,
        onApprove: sb,
        queue: true
      })
      .modal('show')
  }




}