import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from '../global.service';
import { UserProfileServiceService } from './user-profile-service.service'

declare var Parse: any;
declare var ParseObject: any;

@Component({
    selector: 'userProfile',
    templateUrl: 'userProfile.component.html',
    styleUrls: ['userProfile.component.css'],
    providers: [UserProfileServiceService]
})

export class UserProfileComponent implements OnInit {
    username: string = "";
    //_globalservice.focusParseUserObjectOrigin: any;
    secretary_relation: any;
    //g_elderEngFriendsShipbject: any;
    g_secretary: any;
    isLoading = false;
    isRecordLoading = false;
    recordstatusArray = [];
    recordCode = {
        200: "進入dashboard",
        201: "進入單一dashboard",
        310: "電視狀態",
        400: "gate boot success",
    }
    // secretary
    secretaryInfo = {
        secretaryObjectArray: [],
        secretary: [],
        secretaryJustAdded: ""
    }
    SUB_COMPONENT = [
        {
            tabname: '好友',
            focus: () => { this.router.navigate(['./sub/circle'], { relativeTo: this._activatedRoute }) }
        },
        {
            tabname: '裝置',
            focus: () => { this.router.navigate(['./sub/devices'], { relativeTo: this._activatedRoute }) }
        },
        {
            tabname: '訂閱',
            focus: () => { this.router.navigate(['./sub/subsccription'], { relativeTo: this._activatedRoute }) }
        }, {
            tabname: '點數',
            focus: () => { this.router.navigate(['./sub/addPoint'], { relativeTo: this._activatedRoute }) }
        }
    ]
    meta = {
        userNickname: "",
        elderObjuserNickname: ""
    }
    constructor(private router: Router, private _globalservice: GlobalService, private _activatedRoute: ActivatedRoute, private _UserProfileServiceService: UserProfileServiceService) {
        //https://stackoverflow.com/questions/41678356/router-navigate-does-not-call-ngoninit-when-same-page
        _activatedRoute.params.subscribe(val => {
            this.username = this._activatedRoute.snapshot.params['username'];
            if (!this.username)
                return;
            this.isLoading = true;
            this._globalservice.focusParseUserObjectOrigin = null;
            this._UserProfileServiceService.user_inner = null;
            this._UserProfileServiceService.queryUserObjectAndCreateIfConfirmNeeded(this.username)
                .then((user) => {
                    console.log("in save function  with user callback");
                    this._globalservice.focusParseUserObjectOrigin = user;
                    this.meta.userNickname = this._globalservice.focusParseUserObjectOrigin.get("userNickname")
                    return this._UserProfileServiceService.queryElderDetailoBJ(user);
                }).then((user_inner) => {
                    this._UserProfileServiceService.user_inner = user_inner;
                    console.dir(user_inner)
                    this.meta.elderObjuserNickname = this._UserProfileServiceService.user_inner.get("NickName")
                    this.isLoading = false;
                }).catch((error) => {
                    console.error("in useronChange()/exception" + error);
                    this.errorCallback(-2)
                })
        });
    }

    /**
     * 
     * @param type 1 對外暱稱  2 內
     */
    saveUserInfo(type: number) {
        if (!confirm("確定要修改 1/2"))
            return
        if (!confirm("確定要修改 2/2"))
            return
        var input = {};
        switch (type) {
            case 1:
                input = { userNick: this.meta.userNickname, elderuserNick: null, modifiedUserObjid: this._globalservice.focusParseUserObjectOrigin.id }
                break;
            case 2:
                input = { userNick: null, elderuserNick: this.meta.elderObjuserNickname, modifiedUserObjid: this._globalservice.focusParseUserObjectOrigin.id }
                break;
        }
        this.isLoading = true;
        Parse.Cloud.run('modifyUserInfo', input)
            .then(() => { this.isLoading = false; }).catch(() => { this.errorCallback(-12) })
    }

    /**
     * ngOnInit
     */
    ngOnInit(): void {
    }

    /**
     * getRecord
     * get devices usage record
     */
    private getRecord() {
        this.isRecordLoading = true;
        console.log("getRecord " + this._globalservice.focusParseUserObjectOrigin.getUsername());
        this.recordstatusArray = [];
        var Record = Parse.Object.extend("RecordingAction");
        var query = new Parse.Query(Record);
        query.equalTo("affectedUser", this._globalservice.focusParseUserObjectOrigin.getUsername());
        query.descending("createdAt");
        query.find()
            .then((results) => {
                this.isRecordLoading = false;
                for (var i = 0; i < results.length; i++) {
                    var res = results[i];
                    var temp = {
                        "code": this.recordCode[res.get("actionCode")],
                        "status": res.get("status"),
                        "date": res.get("createdAt")
                    }
                    this.recordstatusArray.push(temp)
                }
            }).catch((e) => {
                alert("getRecord fail " + e)
                this.isRecordLoading = false;
            })
    }

    /***
     * username input onchange callback 
     */
    useronChange() {
        console.log("in useronChange()");
        this.router.navigateByUrl('userProfile/' + this.username);
    }

    /**
     * errorCallback
     * @param type 
     */
    errorCallback(type: number) {
        console.error("in errorCallback type= " + type);
        switch (type) {
            case -1:
                alert("錯誤！！ 請重新整理網頁");
                break;
            case -2:
                alert("失敗！！，請再試一次");
                this.isLoading = false;;
                break;
            case -3:
                alert("失敗！！，找不到這台機器的資訊！");
                this.isLoading = false;;
                break;
            case -4:
                alert("失敗！！ 請輸入正確資訊");
                this.isLoading = false;;
            case -5:
                alert("失敗！！ 無法移除自己的權限");
                this.isLoading = false;;
                break;
            case -6:
                alert("已經新增過了喔");
                this.isLoading = false;;
                break;
            case -7:
                alert("失敗！！ 已經 取消 新增!");
                this.isLoading = false;;
                break;
            case -8:
                alert("失敗！！ 已經 取消!");
                this.isLoading = false;;
                break;
            case -9:
                alert("失敗！！ 錯誤的 序號");
                this.isLoading = false;;
                break;
            case -10:
                alert("失敗！！ 請把資料填完整");
                this.isLoading = false;;
                break;
            case -11:
                alert("失敗！！ 機器讀取出現錯誤");
                this.isLoading = false;;
                break;
            case -12:
                alert("失敗！！ 通常是你沒權限");
                this.isLoading = false;;
                break;
            case -13:
                alert("失敗！！ 開通失敗");
                this.isLoading = false;;
                break;
            case -14:
                alert("失敗！！ 請選點數總類");
                this.isLoading = false;;
                break;
        }
    }

    /**
     * successCallback
     * @param type 
     */
    successCallback(type: number) {
        console.error("in successCallback type= " + type);
        switch (type) {
            case 1:
                this.isLoading = false;;
                alert("成功");
                break;
        }
    }
    /**
     * 
     * @param type 
     */
    selectMenu(type: number) {
        switch (type) {
            case 1:
                if (!this._globalservice.focusParseUserObjectOrigin)
                    return;
                console.dir(this._globalservice.focusParseUserObjectOrigin)
                this.router.navigateByUrl('/userProfile/addPoint/' + this._globalservice.focusParseUserObjectOrigin.id);
                break;
        }
    }
    /**
    * activate child route
    */
    componentRefone;
    onActivateone(_componentRef, isActive: boolean) {
        console.warn("onActiveOne isActive= " + isActive)
        if (isActive)
            this.componentRefone = _componentRef;
        else {
            this.componentRefone = null;
        }
    }

}