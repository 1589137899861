import { Injectable } from '@angular/core';
declare var Parse: any;

@Injectable()
export class UserProfileServiceService {

  constructor() { }

  /***
  * query useronject by usernamre
  * @param username
  * @return Promise User
  */
  queryUser(username) {
    console.log("in queryUser username= " + username)
    var User = Parse.Object.extend("_User");
    var query = new Parse.Query(User);
    query.equalTo("username", username);
    query.include('userProfileImageSmall');
    return query.first();
  }
  user_inner;
  queryElderDetailoBJ(userObj) {
    let ElderDetailInfo = Parse.Object.extend("ElderDetailInfo");
    let _ElderDetailInfo = new ElderDetailInfo();
    let query = new Parse.Query(_ElderDetailInfo);
    query.include('User');
    query.equalTo("User", userObj);
    return query.first();
  }
  /**
     * queryUserObjectAndCreateIfConfirmNeeded
     * @param username 
     */
  queryUserObjectAndCreateIfConfirmNeeded(username: string) {
    console.log("in queryUserObjectAndCreateIfConfirmNeeded username= " + username);
    return new Promise((resolve, reject) => {
      if (username == "" || username == null)
        reject();
      this.queryUser(username)
        .then((user) => {
          if (user) {
            resolve(user);
          } else {
            var confirmPhone: string = window.prompt(username + " 不存在將會幫您新增帳號，請在輸入一次您要新增的帳號");
            console.log(confirmPhone)
            if (username === confirmPhone) {
              return this.createInputUser(username, true)
            } else {
              console.log("in save function/ no user callback/ user deny create user");
              throw new Error("user deny create user");
            }
          }
        })
        .then((userobj) => {
          console.log("in save function /createInputUser success");
          resolve(userobj);
        })
        .catch((error) => {
          console.log("in save function  no user callback error= " + error);
          reject(error);
        });
    });
  }

  /**
     * create user by input data 
     * @param username 
     * @param isNeedUserConfirm 
     */
  createInputUser(username: string, isNeedUserConfirm: boolean) {
    console.log("in createInputUser function isNeedUserConfirm= " + isNeedUserConfirm);
    return Parse.Cloud.run('SignUpUser', { username: username, isNeedUserConfirm: isNeedUserConfirm/* secretary: this.secretaryInfo.secretary */ })
  }
}
